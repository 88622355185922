.input-group-text.active input {
    opacity: 1;
}

.input-group-text.active .form-check-label {
    opacity: 1;
}

.input-group.active input {
    opacity: 1;

}

.input-group.active .form-check-label {
    opacity: 1;
}

.form-control.active {
    background-color: initial !important
}

.col-grow {
    max-width: 100%;
    flex-grow: 1;
}

.readonly .chip.btn .close {
    display: none;
}



@media (min-width: 768px) {
    .my-2 {
        flex-basis: 100%;
        min-width: 100%;
    }

}