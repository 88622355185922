
  .select-option .select-option-text{
    overflow: auto;

  }

  .select-option .select-option-text:not(:has(.select-option-secondary-text)) {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;

}
  .select-option{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;

  }
